document.addEventListener('DOMContentLoaded', function () {
  const form = document.querySelector('.woocommerce-shipping-calculator')
  const updateButton = document.getElementById('calc_shipping_button')
  const proceedButton = document.getElementById('proceed_to_checkout_button')
  const countrySelect = document.getElementById('calc_shipping_country')

  if (!form || !updateButton || !proceedButton || !countrySelect) return

  // Initially disable the Proceed to Checkout button
  const disableProceedButton = () => {
    proceedButton.classList.add('inactive', 'pointer-events-none', 'opacity-50')
    proceedButton.setAttribute('aria-disabled', 'true')
  }

  const enableProceedButton = () => {
    proceedButton.classList.remove('inactive', 'pointer-events-none', 'opacity-50')
    proceedButton.removeAttribute('aria-disabled')
  }

  // Disable the Proceed button initially
  disableProceedButton()

  // Listen for the Update button click
  updateButton.addEventListener('click', function () {
    const selectedCountry = countrySelect.value
    console.log('selectedCountry', selectedCountry)

    // Enable Proceed to Checkout button if a valid country is selected
    if (selectedCountry && selectedCountry !== 'default') {
      enableProceedButton()
    } else {
      disableProceedButton()
    }
  })

  // Listen for changes in the country select dropdown
  countrySelect.addEventListener('change', function () {
    const selectedCountry = countrySelect.value

    // Enable Proceed to Checkout button if a valid country is selected
    if (selectedCountry && selectedCountry !== 'default') {
      enableProceedButton()
    } else {
      disableProceedButton()
    }
  })

  // Ensure the Proceed button state is checked on page load if the country is selected
  const initialCountry = countrySelect.value
  if (initialCountry && initialCountry !== 'default') {
    enableProceedButton()
  }
})
