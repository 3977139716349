document.addEventListener('DOMContentLoaded', function () {
  if (document.querySelector('.qty') || document.querySelector('input[name="quantity"]')) {
    return
  }
  function updateCart(itemKey, newQty) {
    const ajaxUrl = `${window.location.origin}/wp/wp-admin/admin-ajax.php`
    const data = new URLSearchParams({
      action: 'woocommerce_update_cart_item',
      cart_item_key: itemKey,
      new_qty: newQty
    })

    // Add class to cart to indicate loading
    document.querySelector('.woocommerce-mini-cart').classList.add('updating-cart')

    fetch(ajaxUrl, {
      method: 'POST',
      body: data
    })
      .then((response) => response.json())
      .then((response) => {
        // Trigger a fragment refresh to update cart content
        document.body.dispatchEvent(new CustomEvent('wc_fragment_refresh'))

        // Update the total amount
        if (
          response &&
          response.fragments &&
          response.fragments['.woocommerce-mini-cart__total .amount']
        ) {
          document.querySelector('.woocommerce-mini-cart__total .amount').innerHTML =
            response.fragments['.woocommerce-mini-cart__total .amount']
        }

        // Remove the updating class
        document.querySelector('.woocommerce-mini-cart').classList.remove('updating-cart')
      })
      .catch(() => {
        // Remove the updating class in case of error as well
        document.querySelector('.woocommerce-mini-cart').classList.remove('updating-cart')
      })
  }

  const miniCart = document.querySelector('.woocommerce-mini-cart')

  if (miniCart) {
    miniCart.addEventListener('change', function (event) {
      if (event.target.classList.contains('qty')) {
        const cart_item_key = event.target.name.match(/\[(.*?)\]/)[1]
        const new_qty = event.target.value
        updateCart(cart_item_key, new_qty)
      }
    })
  }

  // Handle plus/minus button clicks for both single product and cart pages
  document.querySelectorAll('form.cart, .woocommerce-cart-form').forEach(function (form) {
    form.addEventListener('click', function (event) {
      // Check if the clicked element is a plus or minus button
      if (event.target.classList.contains('plus') || event.target.classList.contains('minus')) {
        // Find the closest quantity input related to the clicked button
        var qty =
          event.target.closest('.quantity').querySelector('.qty') ||
          event.target.closest('.quantity').querySelector('input[name="quantity"]')

        if (!qty) return

        var val = parseFloat(qty.value)
        var max = parseFloat(qty.getAttribute('max')) || Infinity
        var min = parseFloat(qty.getAttribute('min')) || 1
        var step = parseFloat(qty.getAttribute('step')) || 1

        // Increase quantity if it's the plus button
        if (event.target.classList.contains('plus')) {
          if (max && max <= val) {
            qty.value = max
          } else {
            qty.value = val + step
          }
        }
        // Decrease quantity if it's the minus button
        else if (event.target.classList.contains('minus')) {
          if (min && min >= val) {
            qty.value = min
          } else if (val > 1) {
            qty.value = val - step
          }
        }

        const updateCartButton = form.querySelector('button[name="update_cart"]')
        if (updateCartButton) {
          updateCartButton.disabled = false // Enable the button
          updateCartButton.style.opacity = 1 // Ensure opacity is reset to normal
        }
      }
    })
  })

  // Handle quantity input and buttons in grouped products table
  const quantityWrappers = document.querySelectorAll('.quantity-table, .woocommerce-cart-form')

  quantityWrappers.forEach((wrapper) => {
    const input = wrapper.querySelector('input[name="quantity"]')
    const minusButton = wrapper.querySelector('.minus')
    const plusButton = wrapper.querySelector('.plus')

    // Ensure all elements exist
    if (!input || !minusButton || !plusButton) {
      return
    }

    const productId = input.getAttribute('data-product-id')

    updateAddToCartLink(productId, input.value)

    const updateQuantity = (newQuantity) => {
      const minValue = parseInt(input.getAttribute('min'), 10) || 1
      const maxValue = parseInt(input.getAttribute('max'), 10) || Infinity

      newQuantity = Math.max(minValue, Math.min(newQuantity, maxValue))

      input.value = newQuantity
      console.log('newQuantity', newQuantity)

      updateAddToCartLink(productId, newQuantity)
    }

    input.addEventListener('input', () => {
      let newValue = parseInt(input.value, 10) || 1
      updateQuantity(newValue)
    })

    minusButton.addEventListener('click', () => {
      let currentValue = parseInt(input.value, 10) || 1
      console.log('currentValue', currentValue)
      if (currentValue > 1) {
        updateQuantity(currentValue - 1)
      }
    })

    plusButton.addEventListener('click', () => {
      let currentValue = parseInt(input.value, 10) || 1
      console.log('currentValue before update', currentValue)

      updateQuantity(currentValue + 1)
    })
  })

  function updateAddToCartLink(productId, quantity) {
    const addToCartLink = document.querySelector(
      `a.custom-add-to-cart[data-product-id="${productId}"]`
    )
    if (addToCartLink) {
      const url = new URL(addToCartLink.href)
      url.searchParams.set('quantity', quantity)
      addToCartLink.href = url.toString()
    } else {
      console.warn('Add to Cart link not found for product ID:', productId)
    }
  }
})
