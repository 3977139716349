// Our main CSS
import '../css/app.css'

//JS
import './quantity.js'
import './menu.js'
import './modal.js'
import './cart.js'

// layzyload and/or play/pause inline videos when they are in/out of view
const autoplaying_videos = document.querySelectorAll('video[playsinline]')

if (autoplaying_videos && autoplaying_videos.length) {
  import('./modules/inline-videos').then((module) => {
    module.default(autoplaying_videos)
  })
}

/**
 * 🦄 Alpine.js
 */
import Alpine from 'alpinejs'
import collapse from '@alpinejs/collapse'
import Clipboard from '@ryangjchandler/alpine-clipboard'

Alpine.plugin(collapse)
Alpine.plugin(Clipboard)

window.Alpine = Alpine

Alpine.start()

/**
 * 🌈 Swiper.js
 */

import Swiper from 'swiper'
import 'swiper/css'
import 'swiper/css/autoplay'

// eslint-disable-next-line no-unused-vars
const swiper = new Swiper('.swiper-container', {
  slidesPerView: 1.1,
  grabCursor: true,
  speed: 2000,
  spaceBetween: 10,

  breakpoints: {
    640: {
      slidesPerView: 1.1,
      spaceBetween: 10
    },
    768: {
      slidesPerView: 2,
      spaceBetween: 20
    },
    1024: {
      slidesPerView: 2,
      spaceBetween: 30
    }
  }
})

/**
 * Prevent right-click on images
 */
const images = document.querySelectorAll('.woocommerce img')

images.forEach((image) => {
  image.addEventListener('contextmenu', function (e) {
    e.preventDefault() // Prevent the right-click menu
  })
})
