document.addEventListener('DOMContentLoaded', function () {
  const openModalButtons = document.querySelectorAll('.modal-open')

  if (openModalButtons.length > 0) {
    openModalButtons.forEach((button) => {
      //eslint-disable-next-line
      button.addEventListener('click', function (event) {
        event.preventDefault()
        toggleModal()
      })
    })

    const overlay = document.querySelector('.modal-overlay')
    overlay.addEventListener('click', toggleModal)

    const closeModalButtons = document.querySelectorAll('.modal-close')
    closeModalButtons.forEach((button) => {
      //eslint-disable-next-line
      button.addEventListener('click', toggleModal)
    })
  }

  function toggleModal() {
    const body = document.querySelector('body')
    const modal = document.querySelector('.modal')

    if (modal.style.display === 'none' || modal.style.display === '') {
      modal.style.display = 'block'
      body.style.overflow = 'hidden' // Prevent body scroll
    } else {
      modal.style.display = 'none'
      body.style.overflow = '' // Restore body scroll
    }
    body.classList.toggle('modal-active')
  }
})
